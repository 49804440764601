<template>
  <b-card>
    <b-row v-if="currentBranch.activityType === 'concrete'">
      <b-col md="3">
        <b-form-group>
          <g-field
            :value.sync="filter.DealerId"
            :dir="isRight ? 'rtl' : 'ltr'"
            label-text="customer"
            field="select"
            name="customer"
            :options="customers"
            label="arabicName"
            @input="() => {
              $refs['items-table'].refreshTable();
            }"
            @change="() => {
              $refs['items-table'].refreshTable();
            }"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <g-field
            :value.sync="filter.projectId"
            :dir="isRight ? 'rtl' : 'ltr'"
            label-text="project"
            field="select"
            name="project"
            :options="projects"
            label="arabicName"
            @input="() => {
              $refs['items-table'].refreshTable();
            }"
            @change="() => {
              $refs['items-table'].refreshTable();
            }"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <g-field
            :value.sync="filter.driverId"
            :dir="isRight ? 'rtl' : 'ltr'"
            label-text="driver"
            field="select"
            name="driver"
            :options="drivers"
            label="arabicName"
            @input="() => {
              $refs['items-table'].refreshTable();
            }"
            @change="() => {
              $refs['items-table'].refreshTable();
            }"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <g-field
            :value.sync="filter.carId"
            :dir="isRight ? 'rtl' : 'ltr'"
            label-text="car"
            field="select"
            name="car"
            :options="cars"
            label="arabicName"
            @input="() => {
              $refs['items-table'].refreshTable();
            }"
            @change="() => {
              $refs['items-table'].refreshTable();
            }"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="currentBranch.activityType === 'concrete'"
      class="mb-1"
    >
      <b-col md="3">
        <!-- code  -->
        <g-field
          id="code"
          v-silent-search
          :value.sync="filter.reservationCode"
          class="mb-0"
          label-text="reservationCode"
          :placeholder="$t('reservationCode')"
          name="code"
          @keydown.enter="
            (v) => {
              $refs['items-table'].refreshTable();
            }
          "
        />
      </b-col>
    </b-row>
    <div class="my-2">
      <div class="row justify-content-between">
        <div class="col-md-3 col-12">
          <!-- code  -->
          <g-field
            id="code"
            v-silent-search
            :value.sync="filter.Code"
            label-text="code"
            class="mb-0"
            name="code"
            @keydown.enter="
              (v) => {
                $refs['items-table'].refreshTable();
              }
            "
          />
        </div>
        <b-col
          md="5"
        >
          <label class="d-block main-font-size mb-50">
            {{ $t('transactionType') }}
          </label>
          <b-form-radio-group
            v-model="filter.transactionType"
            button-variant="outline-warning"
            :options="transactionTypesOptions"
            buttons
            name="radios-btn-default"
            @change="() => {
              $refs['items-table'].refreshTable();
            }"
          />
        </b-col>
        <!-- <div class="col-md-4 col-12">
          </div> -->
        <div class="d-flex align-items-center justify-content-end mb-1 mb-md-0 col-md-4 col-12">
          <vue-excel-xlsx
            :data="itemsArray"
            :columns="tableColumns"
            :filename="this.$route.name"
            :sheetname="'xlsxSheet'"
            class="btn btn-relief-success ml-1 p-0 ptn-sm"
          >
            <img
              src="@/assets/images/icons/xls.jpg"
              alt=""
              style="height: 39px;width: auto;"
            >
          </vue-excel-xlsx>
          <b-button
            variant="relief-danger"
            class="ml-1 p-0 ptn-sm"
            @click="printDocument"
          >
            <img
              src="@/assets/images/icons/pdf.jpg"
              alt=""
              style="height: 39px;width: auto;"
            >
          </b-button>
        </div>
      </div>
    </div>
    <g-table
      ref="items-table"
      :items="invoiceProvider"
      :columns="tableColumns"
      :is-busy="isTableBusy"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: false }"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :searchInput="{ visiable: false }"
      :tbody-tr-class="(item) => {
        if (item && item.isOpening === true) return 'bg-light-info';
      }"
    >
      <template
        v-if="hideActions === false"
        #actions="{ item }"
      >
        <feather-icon
          :id="`invoice-row-${item.id}-prev-icon`"
          icon="EyeIcon"
          data-action-type="preview"
          class="mx-1 clickable "
          :hidden="true"
          @click=" () => { editItem(item) }"
        />
        <b-tooltip
          :title="$t('preview')"
          placement="bottom"
          :target="`invoice-row-${item.id}-prev-icon`"
        />
        <feather-icon
          v-if="item.transactionType === 'manufacturingExchangeOrder'"
          :id="`invoice-row-${item.id}-prev-icon`"
          icon="EyeIcon"
          data-action-type="preview"
          class="mx-1 clickable "
          @click=" () => { editItem(item) }"
        />
        <b-tooltip
          :title="$t('preview')"
          placement="bottom"
          :target="`invoice-row-${item.id}-prev-icon`"
        />
        <b-button
          v-if="item.transactionType === 'manufacturingExchangeOrder'"
          v-b-tooltip.hover.top="$t('print')"
          v-permission=" 'viewAdditionOrders' "
          variant="flat-primary"
          class="btn-icon"
          size="sm"
          @click=" () => { print(item) }"
        >
          <feather-icon
            :id="`invoice-row-${item.id}-preview-icon`"
            icon="PrinterIcon"
          />
        </b-button>
        <div
          v-if="item.transactionType !== 'manufacturingExchangeOrder'"
          class="text-nowrap"
          :set="isConsumed = item.lines.some((i) => i.consumedQuantity)"
        >
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission=" 'editAdditionOrders' "
            variant="flat-primary"
            class="btn-icon"
            data-action-type="edit"
            size="sm"
            @click=" () => { editItem(item) }"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('print')"
            v-permission=" 'viewAdditionOrders' "
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            @click=" () => { print(item) }"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="PrinterIcon"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission=" 'deleteAdditionOrders' "
            :disabled="isConsumed || item.isComplete || item.isPosted"
            variant="flat-danger"
            data-action-type="delete"
            class="btn-icon"
            size="sm"
            @click="(v) => { remove(item) }"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
<script src="@/pages/Shared/html2canvas.js"></script>
<script>
import { storageTypes, storageConcreteTypes } from '@/libs/acl/Lookups';
import GTable from '@/pages/Shared/Table.vue';
import reportMixin from '@/mixin/reportMixin';

export default {
  components: {
    GTable,
  },
   mixins: [reportMixin],
  data() {
    return {
      filter: {
        transactionType: '',
        id: null,
        Code: null,
        dealerId: null,
        storeId: null,
        ItemId: null,
        FromDate: null,
        toDate: null,
        orderClause: null,
        category: 'sales',
        ReservationCode: null,
        DriverId: null,
        ProjectId: null,
        CarId: null,
        reservationCode: null,
      },
      isTableBusy: false,
      items: [],
      totalRows: 0,
      storageTypes,
      storageConcreteTypes,
      itemsArray: [],
      hideActions: false,
      projects: [],
      drivers: [],
      cars: [],
      customers: [],
    };
  },
  computed: {
    transactionTypesOptions() {
      if (this.currentBranch.activityType === 'concrete'){
        return storageConcreteTypes.map((item) => {
        return {
          text: this.isRight ? item.arabicName : item.englishName,
          value: item.id,
        }
      });
      } else {
      return storageTypes.map((item) => {
        return {
          text: this.isRight ? item.arabicName : item.englishName,
          value: item.id,
        }
      });
    }
    },
    tableColumns() {
      return [
        {
          key: 'code',
          field: 'code',
          label: this.$t('code'),
          sortable: true,
        },
        {
          key: 'transactionType',
          field: 'transactionType',
          label: this.$t('transactionType'),
          sortable: true,
          formatter: (key, value, item) => {
            const name = this.isRight ? item.transArabicName : item.transEnglishName;
            if (item.transactionType === 'exchangeOrder') return `<span class="badge badge-primary">${name}</span>`;
            if (item.transactionType === 'additionOrder') return `<span class="badge badge-warning">${name}</span>`;
            if (item.transactionType === 'concreteDelivery') return `<span class="badge badge-dark">${this.$t('ConcreteExchangeOrder')}</span>`;
            if (item.transactionType === 'manufacturing') return `<span class="badge badge-info">${this.$t('manufacturing')}</span>`;
            if (item.transactionType === 'manufacturingExchangeOrder') return `<span class="badge badge-success">${this.$t('manufacturingExchangeOrder')}</span>`;
            return `<span class="badge badge-danger">${name}</span>`;
          },
        },
        {
          key: 'transactionDate',
          field: 'transactionDate',
          label: this.$t('transactionDate'),
          sortable: true,
          formatter: (key, value, item) => {
            return this.getDate(item.transactionDate)
          }
        },
        {
          key: 'exchangeDestinationType',
          field: 'exchangeDestinationType',
          label: this.$t('exchangeMethod'),
          formatter: (key, value, item) => {
            if (item.exchangeDestinationType === 'students' || item.exchangeDestinationType === 'suppliers') return `<span class="badge badge-primary">${this.$t(item.exchangeDestinationType)}</span>`;
            if (item.exchangeDestinationType === 'exchangeDestinations') return `<span class="badge badge-success">${this.$t(item.exchangeDestinationType)}</span>`;
            if (item.exchangeDestinationType === 'agents') return `<span class="badge badge-warning">${this.$t(item.exchangeDestinationType)}</span>`;
            return `<span class="badge badge-danger">${this.$t(item.exchangeDestinationType)}</span>`;
          }
        },
        // {
        //   key: this.isRight ? 'destinationArabicName' : 'destinationEnglishName',
        //   field: this.isRight ? 'destinationArabicName' : 'destinationEnglishName',
        //   label: this.$t('exchangeDestination'),
        //   sortable: true,
        // },
        {
          key: 'dealerArabicName',
          field: 'dealerArabicName',
          label: this.$t('dealer'),
          sortable: true,
        },
        {
          key: 'projectArabicName',
          field: 'projectArabicName',
          label: this.$t('project'),
          tdClass: this.currentBranch.activityType !== 'concrete' || this.filter.transactionType === 'manufacturing' ? 'd-none' : '',
          thClass: this.currentBranch.activityType !== 'concrete' || this.filter.transactionType === 'manufacturing' ? 'd-none' : '',
          sortable: true,
        },
        {
          key: 'driverArabicName',
          field: 'driverArabicName',
          label: this.$t('driver'),
          tdClass: this.currentBranch.activityType !== 'concrete' || this.filter.transactionType === 'manufacturing' ? 'd-none' : '',
          thClass: this.currentBranch.activityType !== 'concrete' || this.filter.transactionType === 'manufacturing' ? 'd-none' : '',
          sortable: true,
        },
        {
          key: 'carArabicName',
          field: 'carArabicName',
          label: this.$t('car'),
          tdClass: this.currentBranch.activityType !== 'concrete' || this.filter.transactionType === 'manufacturing' ? 'd-none' : '',
          thClass: this.currentBranch.activityType !== 'concrete' || this.filter.transactionType === 'manufacturing' ? 'd-none' : '',
          sortable: true,
        },
        {
          key: 'totalQuantity',
          field: 'totalQuantity',
          label: this.$t('quantity'),
          sortable: true,
        },
        {
          key: 'updatedStatus',
          field: 'updatedStatus',
          label: this.$t('status'),
          sortable: false,
          formatter: (key, value, tanx) => {
            if (tanx.isPosted) return `<span class="badge bg-secondary bg-darken-1">${this.$t('posted')}</span>`
            return `<span class="badge border-0 bg-primary bg-darken-1"></span>`;
          },
        },
        {
          key: 'sourceTransactionCode',
          field: 'sourceTransactionCode',
          label: this.$t('attachedToInvoice'),
          formatter: (key, value, tanx) => {
            if (tanx.transactionType === 'manufacturingExchangeOrder') return `<span class="badge bg-warning bg-darken-1">${this.$t('manufacturing')} - ${tanx.sourceTransactionCode}</span>`
            if (tanx.transactionType !== 'manufacturingExchangeOrder' && tanx.sourceTransactionCode) return `<span class="badge border-0 bg-primary bg-darken-1">${this.$t('invoiceCode')} - ${tanx.sourceTransactionCode}</span>`;
          },
          sortable: true,
        },
        {
          key: 'actions',
        },
      ];
    },
  },
  beforeMount() {
    if (this.currentBranch.activityType === 'concrete') {
      this.getProjects();
    }
  },
  mounted() {
  },
  methods: {
    hide() {
      this.hideActions = true
    },
    pdfExport(name) {
      html2canvas(document.getElementById('mainTable')).then(function(canvas){
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
          doc.save(`${name}.pdf`);
      });
    },
    addTdConClass() {
         if (this.currentBranch.activityType === 'concrete') {
           return 'd-none'
         }
         return 'null'
      },
      addTdNotConClass() {
         if (this.currentBranch.activityType !== 'concrete') {
           return 'd-none'
         }
         return 'null'
      },
    printDocument() {
      this.hide()
        setTimeout(() => {this.pdfExport(this.$route.name)
        this.hideActions = false
        }, 500);
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: `ItemTransactions/${item.transactionType}`, id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.$refs['items-table'].refreshTable();
          });
        }
      );
    },
    editItem(item) {
      let name = 'pos-invoice-edit';
      if (item.transactionType === 'exchangeOrder') name = 'exchange-order-edit';
      else if (item.transactionType === 'additionOrder') name = 'addition-order-edit';
      else if (item.transactionType === 'manufacturing') name = 'manufacturing-order-edit';
      else if (item.transactionType === 'manufacturingExchangeOrder') name = 'manufacturingExchangeOrder';
      else if (item.transactionType === 'concreteDelivery') name = 'concrete-exchange-order-edit';
      this.$router.push({
        name,
        params: { id: item.id },
      });
    },
    invoiceProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;

      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      let params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

      params += this.getFilterObj(this.filter);

      this.isTableBusy = true;
        this.get({ url: `itemTransactions/categories/stock${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount === 0 ? 1 : totalCount;
          data.forEach((item) => {
            // item.paid = item.payments.reduce((total, val) => {
            //   total += val.value;
            //   return total;
            // }, 0);
            const transactionName = this.storageTypes.find((val) => val.id === item.transactionType) || {};
            item.transArabicName = transactionName.arabicName;
            item.transEnglishName = transactionName.englishName;
            item.totalQuantity = 0;
            item.lines.forEach((li) => {
              item.totalQuantity += li.quantity;
            })
            item.totalQuantity = this.fraction(item.totalQuantity);
          });
          callback(data);
          this.itemsArray = data;
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        // });
      })
      return null;
    },
    getProjects() {
      this.get({ url: 'Projects' }).then((data) => {
        this.projects = data;
      }).then(() => {
        this.get({ url: 'cars' }).then((data) => {
        this.cars = data;
      }).then(() => {
        this.get({ url: 'Drivers?Type=mixerDriver'}).then((data) => {
        this.drivers = data;
      });
      }).then(() => {
        this.get({ url: 'Customers/summary' }).then((data) => {
          this.customers = data;
      });
      })
      })
    },
      print(item) {
      const printedItem = {
        id: item.id
      }
      let repName = '';
      if (item.transactionType === 'exchangeOrder') repName = 'ExchangeOrder-ar'
      else if (item.transactionType === 'additionOrder') repName = 'AdditionOrder-ar';
      else if (item.transactionType === 'manufacturing') repName = 'ManufacturingOrder-ar';
      else if (item.transactionType === 'manufacturingExchangeOrder') repName = 'ManufacturingExchangeOrder-ar';
      else if (item.transactionType === 'concreteDelivery') {
        repName = 'ExchangeOrder-ar';
      }
       this.printReport(repName, printedItem);
    },
  },
};
</script>
<style></style>
